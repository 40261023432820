import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';

function Timer({ip, time, site, display, url, disable}) {
const [disabled, setDisabled] = useState(false);
let [timerClock, setTimerClock] = useState(time);

function convertTime(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
     // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (value < 1) {
        return display;
    }
    return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
}
function checkVoted() {
    if (timerClock < 0 ) {
        const checking = setInterval(function() {
            axios.get(`https://elysiumps.com/api/api.php?request=toplist&site=${site}&ip=${ip}`).then(response => {
            if (response.data.length > 0) {
                let time = response.data.length > 0 ? response.data[0].time : 0;
                let voted = moment.unix(time).add(12, 'hours');
                let current = moment().valueOf();   
                let timeDiff = voted.diff(current, 'seconds');
                setTimerClock(timeDiff);
            }
            });
        }, 30000);

        return () => {
            clearInterval(checking);
        }
    }
}
useEffect(() => {
    if (timerClock > 0) {
        const timer = setInterval(function() {
            setTimerClock(timerClock - 1);
            setDisabled(true);
        }, 1000)
        
        return () => { // this runs as the clean up function for the useEffect
           clearInterval(timer)
        }
    } else {
        setDisabled(false);
    }
}, [timerClock]);

  return (
    <span className="timer">
        <a onClick={(e) => checkVoted()} href={url} className={`btn btn-dark btn-lg vote-btn ${disabled && 'disabled'} ${disable.length < 1 && 'disabled'}`} role="button" target="_blank">{convertTime(timerClock)}</a>   
    </span>
  )
}

export default Timer
