import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VoteButton from '../Vote/VoteButton';

function Vote() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(false);
  const [ip, setIP] = useState('');
  const [username, setUsername] = useState('');
  const [voteData, setVoteData] = useState([]);
  const convertIp = () => {
    var chars = {'0':'x1','1':'y2','2':'z3', '3':'c4', '4':'j5', '5':'a6', '6':'b7', '7':'w8', '8':'m9', '9':'o', '.': '-'};
    return ip.replace(/[0-9.]/g, m => chars[m]);
  }
  const toplists = [
    {
      site: "topg",
      display: "TopG",
      url: "https://topg.org/runescape-private-servers/server-645434-"+username,
    },
    {
      site: "runelocus",
      display: "RuneLocus",
      url: "https://www.runelocus.com/top-rsps-list/ospk/vote/?callback="+username+"_"+convertIp(),
    },
    {
      site: "runelist",
      display: "RuneList",
      url: "https://runelist.io/toplist/server/219/vote/"+username+"_"+convertIp(),
    },
    {
      site: "rspslist",
      display: "RSPSList",
      url: "https://www.rsps-list.com/index.php?a=in&u=ospk&id="+username+"_"+convertIp(),
    },
    {
      site: "arenatop",
      display: "Arena Top100",
      url: "https://www.arena-top100.com/index.php?a=in&u=ospk&id="+username+"_"+convertIp(),
    },
  ];
  async function getIp() {
    const res = await axios.get('https://api64.ipify.org?format=json')
    setIP(res.data.ip);
  }
  useEffect(() => {
    getIp();
  }, [user]);
  return (
    <div className="vote-container">
      <div className="flex-row vote-info__header">
        <h1 className="text-left">Vote Page - {!user ? 'Guest' : username}</h1>
        {user && <span onClick={() => setUser(false)} className="text-right border-box auto-vert"><i className="fas fa-undo"></i></span>}
      </div>
        <div className="vote-info centered box">
          <h1 className="text-center">Vote for Elysium</h1>
          <div className="vote-info__box centered-half box">
              <h3>Why Vote?</h3>

              <p>Voting for ElysiumRSPS will give our server more publicity in turn bringing us more players. Now that is a big reason to vote, so you all have more
              players to enjoy the game with. </p>
              
              <p>Voting also gives you unique rewards, after voting on the sites below you will be able to <strong>::voted</strong> special points 
              ingame and spend those at our unique shop!</p>
          </div>
          {!user &&
              <div className="input-group mb-3 centered-half">
                <input type="text" className="form-control coupon" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} defaultValue={username} /> 
                <div className="input-group-append">
                    <button className="btn btn-dark form-control apply" onClick={() => setUser(true)} disabled={username.trim().length < 1 ? 'disabled' : ''}>Go</button>
                </div>
              </div>
          }
          {ip &&
            <div className="vote-buttons flex-row wrap">
              {toplists.map((site) => (
                <VoteButton key={site.site} ip={ip} site={site.site} display={site.display} url={site.url} disabled={username.trim()}/>
              ))}
            </div>
          }
        </div>
    </div>
  )
}

export default Vote