import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
        <div className="divider_two"><div className="diamond"></div></div>
      <footer className="py-5 bg-dark">
        <div className="container">
          <div className="flex-row">
            <div className="logo" ><img src="assets/logo.png"/></div>
              <div className="flex-column">
                <h4>Elysium</h4>
                <span className="row justify-content-center"><Link to="/">Home</Link></span>
                <span className="row justify-content-center"><a href="https://elysiumps.com/reign.jar" alt="Play Elysium Now!">Play</a></span>
                <span className="row justify-content-center"><Link to="/vote">Vote</Link></span>
                <span className="row justify-content-center"><Link to="/hiscores">Hiscores</Link></span>
              </div>
              <div className="flex-column">
                <h4>Community</h4>
                <span className="row justify-content-center"><a href="https://discord.gg/jtzHFgVUzW" target="_blank">Discord</a></span>
              </div>
              <div className="flex-column">
                <h4>Support</h4>
                <span className="row justify-content-center"><a href="">Rules</a></span>
                <span className="row justify-content-center"><a href="">FAQ</a></span>
              </div>
          </div>
          <div className="copyright"><small>Copyright	&copy; Elysium.</small></div>
        </div>
      </footer>
    </div>
  )
}

export default Footer