import React, { useEffect, useState } from 'react';
import { CartState } from '../../context/Context';
import { getBasketTotal } from '../../context/reducer';
import CheckoutBox from './CheckoutBox';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ThankYou from './thankyou';

function PaypalCheckout({cSavings}) {
  const { state: { cart, user }, dispatch } = CartState();
  const total = getBasketTotal(cart);
  const totalSavings = cSavings > 0 ? total * (cSavings / 100) : 0;
  const cTotal = total - totalSavings;
  const [customId, setCustomId] = useState('');
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  return (
    <div className="checkout paypal centered">
    {loading &&
      <i className="fas fa-spinner fa-spin fa-5x centered"></i>
    || finished &&
      <ThankYou details={details} cTotal={cTotal}/>
    || 
    <div className="box flex-column">
        <div className="checkout-header">
          <div className="hello-box flex-row"><div className="hello-username">Checkout cart, <strong className="username">{user}</strong></div></div>
        </div>
        <div className="checkout-value flex-column">
           <span><a href="shop">Cancel</a></span>
        </div>
        <div className="checkout-items">
            {cart.map((c) => (
                <CheckoutBox key={c.name}data={c} order={true}/>
            ))}
        </div>
        {total > 0 &&
        <div>
            <div className="checkout-value flex-column">
                    <span>Value: ${total}</span>
                    <span>Total: ${cTotal}</span>
                    {cSavings > 0 && <span>${totalSavings} savings ({cSavings}%)</span>}
            </div>
            <div className="checkout-button">
              <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY}}>
                <PayPalButtons
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        styles: {
                          color: 'blue', 
                          'color': 'purple',
                          'input': {
                            'background': 'blue !important',
                            'color': '#3A3A3A',
                            'transition': 'color 160ms linear',
                            '-webkit-transition': 'color 160ms linear'
                          },
                        },
                        purchase_units: [
                            {
                                amount: {
                                    value: cTotal,
                                },
                                custom_id: user,
                            },
                        ],
                        application_context: {
                          shipping_preference: "NO_SHIPPING"
                        }
                      });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                           setDetails(details);
                           setLoading(true);
                           setTimeout(function() {
                            setLoading(false);
                            setFinished(true);
                           }, 3000);
                        });
                    }}
                    style={{
                      color: "black",
                      height: 48,         
                      shape: "pill",
                      
                    }}
                />
              </PayPalScriptProvider>
            </div>
        </div>
        }
    </div>
    }
</div>
  )
}

export default PaypalCheckout