import React from 'react'

function Home() {
  return (
    <div>
    <div className="divider"><div className="diamond"></div></div>
    <div className="home-content">
      <div className="py-5 bg-image-full slider">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
                <h2 className="welcome">Welcome to Elysium</h2>
                <p className="mb-0">We are an economy-based server offering an exciting blend of PvP, Ironman mode, and a variety of other features. Our goal is to provide a dynamic and immersive experience for all players.</p>
                <p className="mb-0">Join us today and discover everything our vibrant server has to offer!</p>
            </div>
          </div>
          <div className="flex-row">
            <div className="box images">
              <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="assets/c1.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <p>Arenas & Battlegrounds</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="assets/c2.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <p>Item Enchanting</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="assets/c4.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <p>Free Donator Ranks</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="assets/c3.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <p>Wilderness Expansion</p>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="flex-column">
              <div className="header-box">
                <h4 className="box-header">Download</h4>
                <p>Join now by downloading our client down below. </p>
                <p><a href="https://elysiumps.com/elysium.jar" className="btn btn-dark" role="button">Download now</a></p>
              </div>
              <div className="header-box">
                <h4 className="box-header">Discord</h4>
                <p>Stay up to date with the latest updates and chat with other players!</p>
                <p><a href="https://discord.gg/jtzHFgVUzW" className="btn btn-dark" target="_blank" role="button">Join discord</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider_two"><div className="diamond"></div></div>
      <div className='tab-content'>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="text-left">Follow our latest Updates</h2>
              <div className="flex-row news">
  
                <div className="box">
                  Beta server live!
                </div>
                <div className="box">
                  New website!
                </div>
                <div className="box">
                  Coming soon!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"><div className="diamond"></div></div>
      <div className="py-5 bg-image-full imgbox">
        <img src="assets/section_3.png" />
      </div>
      <div className="divider_two"><div className="diamond"></div></div>
      <div className="tab-content imgbox imgbox">
        <img className="center-fit" src="assets/section_2.png" />
      </div>
      <div className="divider"><div className="diamond"></div></div>
      <div className="py-5 bg-image-full imgbox">
        <img src="assets/section_1.png" />
      </div>
    </div>  
    </div>    
  )
}

export default Home