import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CartState } from '../../context/Context';
import { getBasketTotal } from '../../context/reducer';
import CheckoutBox from './CheckoutBox';

function ThankYou({details, cTotal}) {
    const { state: { cart, user }, dispatch } = CartState();
    const [error, setError] = useState('');
    const total = getBasketTotal(cart);
    cTotal = cTotal.toFixed(2);
    useEffect(() => {
        if (cTotal === details.purchase_units[0].amount.value) {
            axios.post('https://elysiumps.com/api/api.php?request=payments&status=completed', {
                order_id: details.id,
                username: user,
                time: details.update_time,
                cart: JSON.stringify(cart),
                total: total,
                paid: details.purchase_units[0].amount.value,
                claimed: false
           }).catch(function (error) {
                console.error(error);
            });
        } else {
            setError('Sorry something went wrong. Try again later, if this keeps happening please contact an Admin.');
        }
    }, []);
  return (
    <div>
    {!error && 
    <div className="box">
        <div className="hello-box flex-row">
            <h3>Order #{details.id}</h3>
        </div>
        <div className="hello-box">
            <p>Thank you for your order, {user}. To claim your items log in game and type ::donated.</p>
            
            <p>Thank you for supporting Elysium!</p>
        </div>
        {cart.map((c) => (
            <CheckoutBox key={c.name} data={c} order={true}/>
        ))
        }
    </div>
    ||
        <div className="box">{error}</div>
    }
    </div>
  )
}

export default ThankYou