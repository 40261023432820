import React, { useState } from 'react';
import { CartState } from '../../context/Context';
import { Toast, ToastContainer } from 'react-bootstrap';

function CheckoutBox({data, order}) {
    const { state: { cart, user }, dispatch } = CartState();
    const [showRemoved, setShowRemoved] = useState(false);
    const [title, setTitle] = useState('');
    function Dispatch(todo) {
        setTitle(data.name);
        dispatch({
            type: todo, 
            payload: data
        });
        setShowRemoved(true);
    }
  return (
    <div className="checkout-item">
        <ToastContainer position="bottom-start" className="p-3">
            <Toast onClose={() => setShowRemoved(false)} show={showRemoved} delay={1000} autohide>
            <Toast.Body>
                Removed <strong>{title}</strong> from your cart</Toast.Body>
            </Toast>
        </ToastContainer>
        <div className="flex-row">
            <div className="checkout-item__first relative">
                <span className="checkout-image__pricebox">$ {data.price}</span>
                <div className="checkout-item__image"><img src={`https://elysiumps.com/assets/store/${data.image}`} alt="" /></div>
            </div>
            <div className="checkout-item__info flex-column">
                <div className="checkout-item__name">
                    {data.name}
                </div>
                <div className="checkout-item__desc" dangerouslySetInnerHTML={{__html: data.desc}}>
                </div>
            </div>
            <div className="checkout-item__misc flex-column">
                {!order &&
                    <div className="checkout-item__remove" onClick={() => Dispatch('REMOVE_FROM_CART')}>
                        x
                    </div>
                }
                <div className="checkout-item__qty">
                    x{data.qty}
                </div>
            </div>
        </div>
    </div>
  )
}

export default CheckoutBox