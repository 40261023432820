import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductBox from '../Shop/ProductBox';
import CheckoutBox from '../Shop/CheckoutBox';
import { CartState } from '../../context/Context';
import { getBasketTotal } from '../../context/reducer';
import PaypalCheckout from '../Shop/PaypalCheckout';


function Shop() {
    const [products, setProducts] = useState([]);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [paypal, setPaypal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [coupon, setCoupon] = useState('');
    const [cSavings, setcSavings] = useState('0');
    const [username, setUser] = useState('');
    const { state: { cart, user }, dispatch } = CartState();
    const total = getBasketTotal(cart);
    const totalSavings = cSavings > 0 ? total * (cSavings / 100) : 0;
    const cTotal = total - totalSavings;

    function getProducts() {
        axios.get(`https://elysiumps.com/api/api.php?request=products`).then(response => {
            setProducts(response.data);
        }).catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
    function checkCoupon() {
        axios.get(`https://elysiumps.com/api/api.php?request=coupon&code=${coupon}`).then(response => {
            if (response.data[0].count > 0) {
                setcSavings(response.data[0].savings);
            } else {
                setcSavings('0');
            }
        }).catch(err => console.error(err));
    }
    function checkout() {
        setCheckoutLoading(true);
        setTimeout(function() {
            setCheckoutLoading(false);
            setPaypal(true);
        }, 2000);
    }
    useEffect(() => {
        getProducts();
    }, []);
  return (
    <div className="shop container">
        <div className="flex-row no-space"><h3>Store</h3></div>
        {!loading && !paypal &&
        <div className="flex-row checkout-flex">
            <div className="checkout">
                <div className="box flex-column">
                    <div className="checkout-header">
                        {user === null &&
                            <div className="input-group mb-3">
                                <input type="text" className="form-control coupon" placeholder="Enter username" onChange={(e) => setUser(e.target.value)} defaultValue={username.trim().length > 0 ? username : ''}/>
                                <div className="input-group-append">
                                    <button className="btn btn-dark form-control apply" onClick={() => dispatch({type: 'SET_USER',user: username})} disabled={username.trim().length < 1 ? 'disabled' : ''}>Go</button>
                                </div>
                            </div>
                        ||
                            <div className="hello-box flex-row"><div className="hello-username">Hello, <strong className="username">{user} </strong></div> <small className="hi-reset float-right border-box"><a onClick={() => dispatch({type: 'SET_USER',user: null})}><i className="fas fa-undo"></i></a></small></div>
                        }
                    </div>
                    <div className="checkout-coupon">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control coupon" placeholder="Enter coupon" onChange={(e) => setCoupon(e.target.value)}/>
                            <div className="input-group-append">
                                <button className="btn btn-dark form-control apply" onClick={() => checkCoupon()}>Apply</button>
                            </div>
                        </div>
                        {cSavings < 1 && coupon.length > 0 &&
                            <span className="invalid">Invalid</span>
                        }
                    </div>
                    <div className="checkout-items">
                        {cart.map((c) => (
                            <CheckoutBox key={c.name} data={c} />
                        ))}
                    </div>
                    {total > 0 &&
                    <div>
                        <div className="checkout-value flex-column">
                                <span>Value: ${total}</span>
                                <span>Total: ${cTotal}</span>
                                {cSavings > 0 && <span>${totalSavings} savings ({cSavings}%)</span>}
                        </div>
                        <div className="checkout-button">
                            <span>{checkoutLoading ? <i className="fas fa-spinner fa-spin fa-1x"></i> : <a className='btn btn-dark btn-lg' onClick={() => checkout()}>Checkout</a> }</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="flex-row wrap products-flex">    
                {products.length < 1 && 
                <div className="donation-box flex-column">
                    <h3>Please try again later...</h3>
                </div> 
                }
                {products.map((p) => (
                    <ProductBox key={p.name} data={p} />
                ))}
            </div>
        </div>
        || loading && 
        <div className="text-center">
            <i className="fas fa-spinner fa-spin fa-8x"></i>
        </div>
        || paypal &&
            <PaypalCheckout cSavings={cSavings}/>
        }
    </div>
  )
}

export default Shop