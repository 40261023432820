import React, {createContext, useContext, useReducer} from 'react';
import { cartReducer } from './reducer';

const Cart = createContext();

const Context = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, {
        cart: [],
        user: null
    });
  return (
    <Cart.Provider value={{state, dispatch}}>{children}</Cart.Provider>
  )
}

export default Context;

export const CartState = () => {
    return useContext(Cart);
}