import React, { useState } from 'react';
import { CartState } from '../../context/Context';
import { Toast, ToastContainer } from 'react-bootstrap';

function ProductBox({data}) {
    const { state: { cart, user}, dispatch } = CartState();
    const [show, setShow] = useState(false);
    function Dispatch(todo) {
        setShow(true);
        dispatch({
            type: todo, 
            payload: {
                id: data.id,
                item_id: data.item_id,
                image: data.image,
                name: data.name,
                price: data.price,
                desc: data.description,
                qty: 1
            }
        });
    }
  return (
    <div className="donation-box box flex-column">
        <div className="flex-row product-info"><h2>{data.name}</h2> <i className="fas fa-solid fa-info-circle fa-2x icon" data-toggle="modal" data-target={`#item_${data.id}Modal`}></i></div>
        <div className="box relative">
            <span className="absolute">$ {data.price}</span>
            <img src={`assets/store/${data.image}`} alt="" />
        </div>
        {user !== null && <a className="btn btn-dark add-to-cart" role="button" onClick={() => Dispatch('ADD_TO_CART')}>Add to cart</a>}
        
        <ToastContainer position="bottom-start" className="p-3">
            <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
            <Toast.Body>
                Added <strong>{data.name}</strong> to your cart</Toast.Body>
            </Toast>
        </ToastContainer>
        
        <div className="modal fade" id={`item_${data.id}Modal`} tabIndex="-1" role="dialog" aria-labelledby={`item_${data.id}ModalLabel`} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`item_${data.id}ModalLabel`}>{data.name}</h5>
                    </div>
                    <div className="modal-body" dangerouslySetInnerHTML={{__html: data.description}}>
                    </div>
                    <div className="modal-footer">
                        <a role="button" className="btn-dark" data-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default ProductBox