import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useLocation} from "react-router-dom";
import HiscoresProfile from '../Hiscores/HiscoresProfile.js';
import Compare from '../Hiscores/Compare';
import NumberFormat from 'react-number-format';
import '../css/Hiscores.css';


function Hiscores() {

const [loading, setLoading] = useState(true);
const [loadMore, setLoadMore] = useState(false);
const [players, setPlayers] = useState([]);
const [searchTerm, setSearchTerm] = useState('');
const [comparing, setComparing] = useState(false);
const [playerOne, setPlayerOne] = useState(null);
const [playerTwo, setPlayerTwo] = useState(null);
const [loadProfile, setProfile] = useState('');
const [limit, setLimit] = useState(20);

const skills = ['Overall', 'Attack', 'Defence', 'Strength', 'Hitpoints', 'Ranged', 'Prayer', 'Magic', 'Cooking', 'Woodcutting', 'Fletching', 'Fishing', 'Firemaking', 'Crafting', 'Smithing', 'Mining', 'Herblore', 'Agility', 'Thieving', 'Slayer', 'Farming', 'Runecrafting', 'Hunter', 'Construction'];
const search = useLocation().search;
const getSkill = (skills.indexOf(capitalizeFirstLetter(new URLSearchParams(search).get('skill'))) > -1 ? new URLSearchParams(search).get('skill') : 'Overall');
const [skill, setSkill] = useState(getSkill);
let rank = 1;

const fetchData = async (skill) => {
  setPlayers([]);
  axios.get(`https://elysiumps.com/api/api.php?request=hiscores&skill=${skill}_exp`).then(response => {
    setPlayers(response.data);
  }).catch(err => console.error(err))
  .finally(() => setLoading(false));
}
const fetchMore = async () => {
  setLoadMore(true);
  setTimeout(function() {
    setLimit(limit + 10);
    setLoadMore(false);
  }, 3000);
}

function showSkills(value) {
  setLoading(true);
  document.getElementById("v-pills-"+skill+"-tab").classList.remove("active");
  if (comparing) {
    setComparing(false);
    setPlayerOne(null);
    setPlayerTwo(null);
  } else {
    setSkill(value);
    window.history.pushState('Hiscores - '+value, value, '/hiscores?skill='+value);
  }
}
function capitalizeFirstLetter(string) {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1);
}
async function compareUsers(e, player) {
    if (!e.target.checked) {
        setPlayerOne('');
      }
      if (!playerOne && e.target.checked) {
        setPlayerOne(player);
      } else if (!playerTwo && e.target.checked) {
        setPlayerTwo(player);
        setComparing(true);
      }
}

useEffect(() => {
    fetchData(skill);
    document.getElementById("v-pills-"+skill+"-tab").classList.add("active");
}, [skill])
  return (
    <div className='hiscores container'>
      <div className="flex-row-between">
        <h1 className="text-left">Hiscores - {skill}</h1>
        <input type="search" className="form-control dark" placeholder="Search user..." onChange={(e) => {setSearchTerm(e.target.value)}} />
      </div>
      <div className="row">
        <div className="col-lg-auto">
          <div className="d-flex align-items-start">
            <div className="skills_side_bar nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              {skills.map(skill => (
                <button key={skill} className="nav-link" id={`v-pills-${skill}-tab`}  onClick={() => showSkills(skill)}>
                  <span className="skills_image"><img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/skill_icon_${skill}.gif`} width={24} height={24}/></span>
                  </button>
              ))}
            </div>
          </div>
        </div>
        <div className="col">
        {comparing && <Compare skills={skills} playerOne={playerOne} playerTwo={playerTwo} />}
        {!loading && !comparing &&
          <table className="table table-striped table-dark">
            <thead>
              <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">{skill} Lvl</th>
              <th scope="col">Experience</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {players.filter((player) => {
              if (searchTerm == "") {
                return player;
              } else if (player.username.toLowerCase().includes(searchTerm.toLowerCase())) {
                return player;
              }
            }).slice(0,limit).map((p) => (
            <><tr key={p.username} className={p.username} onClick={() => setProfile(p.username)}>
                <td data-bs-toggle="collapse" href={`#${p.username.replace(/\s/g, "-")}`} role="button" aria-expanded="false" aria-controls={p.username}>{rank++}</td>
                <td data-bs-toggle="collapse" href={`#${p.username.replace(/\s/g, "-")}`} role="button" aria-expanded="false" aria-controls={p.username}>{p.username}</td>
                <td data-bs-toggle="collapse" href={`#${p.username.replace(/\s/g, "-")}`} role="button" aria-expanded="false" aria-controls={p.username}>{p[skill.toLowerCase() + "_level"]}</td>
                <td data-bs-toggle="collapse" href={`#${p.username.replace(/\s/g, "-")}`} role="button" aria-expanded="false" aria-controls={p.username}><NumberFormat value={p[skill.toLowerCase() + "_exp"]} displayType={'text'} thousandSeparator={true} /></td>
                <td><input type="checkbox" className="form-check-input" onClick={(e) => compareUsers(e, p)} /></td>
              </tr>
              <tr> 
                <td colSpan="5">
                  <div className="collapse" id={p.username.replace(/\s/g, "-")} data-bs-parent=".table">
                    <div className="card-body profile">
                      {loadProfile === p.username &&
                        <HiscoresProfile
                            player={p}
                            skills={skills} />
                          
                      }
                    </div>
                  </div>
                </td>
              </tr></>
            ))}
            </tbody>
          </table>
        }
        {!comparing && 
          <div className="loading" onClick={() => fetchMore()}>{loading ? <i className="fas fa-spinner fa-spin fa-5x"></i> : loadMore ? <i className="fas fa-spinner fa-spin fa-3x"></i> : limit >= players.length ? '' : <a className="btn btn-dark btn-lg" role="button">More</a>}</div>
        }
        </div>
      </div>
    </div>
  )
}
export default Hiscores
