export const getBasketTotal = (cart) => cart?.reduce((amount, item) => (item.price * item.qty) + amount, 0);

export const cartReducer = (state, action) => {
    switch(action.type) {
        case "ADD_TO_CART":
            let addIndex = state.cart.findIndex((basketItem) => basketItem.id === action.payload.id);
            if (addIndex >= 0) {
                return {
                    ...state,
                    cart: state.cart.map(item => item.id === action.payload.id
                        ? 
                        {
                          ...item,
                          qty: item.qty + 1,
                          //price: (item.price / item.qty) * (item.qty + action.payload.qty)
                        }
                        : item
                      )
                }
            } else 
                return {...state, cart: [...state.cart, { ...action.payload}] }
        case "REMOVE_FROM_CART":
            return {
                ...state, 
                cart: state.cart.filter((c) => c.id !== action.payload.id)
            }
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            };   
        default: 
            return state;
    }
}