import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Timer from './Timer'

function VoteButton({ip, display, site, url, disabled}) {
  const [votedData, setVotedData] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`https://elysiumps.com/api/api.php?request=toplist&site=${site}&ip=${ip}`).then(response => {
      let time = response.data.length > 0 ? response.data[0].time : 0;
      let voted = moment.unix(time).add(12, 'hours');
      let current = moment().valueOf();   
      let timeDiff = voted.diff(current, 'seconds');
      setTimeLeft(timeDiff);
    }).catch(err => console.error(err)).finally(setTimeout(function() {setLoading(false)}, 1500));
  }, []);
  return (
    <div>
      {loading &&
        <div className="loading text-center vote-loading"><i className="fas fa-spinner fa-spin fa-2x"></i></div>
      ||
        <Timer ip={ip} time={timeLeft} site={site} display={display} url={url} disable={disabled}/>
      }
    </div>
  )
}

export default VoteButton