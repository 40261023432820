import React from 'react'
import { Link } from 'react-router-dom'

function Header({display}) {
  return (
    <div className="header-bg">
    <div className="above-nav">
      <div className="container">
      </div>
    </div>
    <div className="nav">
        <div className="container">
            <div className="flex-row nav-flex">
              <div className={`nav_item ${display == "home" ? 'current' : ''}`}><Link to="/">Home</Link></div>
              <div className="nav_item divider"><img src="assets/divider.png" /></div>
              <div className="nav_item play"><a href="https://elysiumps.com/elysium.jar" >Play now</a></div>
              <div className="nav_item mobile divider"><img src="assets/divider.png" /></div>
              <div className="nav_item discord"><a href="https://discord.gg/jtzHFgVUzW" target="_blank">Discord</a></div>
              <div className="nav_item mobile divider"><img src="assets/divider.png" /></div>
              <div className="nav_item logo" ><img src="assets/logo.png"/></div>
              <div className="nav_item mobile divider"><img src="assets/divider.png" /></div>
              <div className={`nav_item ${display === "vote" ? 'current' : ''}`}><Link to="/vote">Vote</Link></div>
              <div className="nav_item divider"><img src="assets/divider.png" /></div>

              <div className="nav_item mobile_logo"><img src="assets/logo.png" /></div>
              <div className="nav_item mobile mobile_hide divider"><img src="assets/divider.png" /></div>

              <div className={`nav_item ${display == "shop" ? 'current' : ''}`}><Link to="/shop">Shop</Link></div>
              <div className="nav_item divider"><img src="assets/divider.png" /></div>
              <div className={`nav_item ${display == "hiscores" ? 'current' : ''}`}><Link to="/hiscores">HiScores</Link></div>
            </div>
        </div>
    </div>
    {display == "home" && 
        <section className="py-5 top">
        <div className="container my-5">
            <div className="row justify-content-center">
            <div className="col-lg-6 header-text">
                <h1 className="lead lead-header"><img src="assets/Title.png" alt="" /></h1>
                <div className="lead play-now"><a href="https://elysiumps.com/elysium.jar" target="_blank" className="btn play-button" role="button" title="Play Elysium Now!"></a></div>
            </div>
            </div>
        </div>
        <div className="gap-content">Play with <br /><span className="ani">0</span> others</div>
        </section>
    }
  </div>
  )
}

export default Header