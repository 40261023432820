import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import axios from 'axios';

function Compare({skills, playerOne, playerTwo}) {
    const [rankOne, setRankOne] = useState([]);
    const [rankTwo, setRankTwo] = useState([]);
    function getRank(username, player) {
        if (player === "one")
            setRankOne([]);
        else
            setRankTwo([]);
        skills.map((skill) => {
            axios.get(`https://elysiumps.com/api/api.php?request=hiscores_rank&skill=${skill}&user=${username}`).then(response => {
              if (player === "one") setRankOne((rank) => [...rank, response.data[0].rank]);
              else setRankTwo((rank) => [...rank, response.data[0].rank]);
            }).catch(err => console.error(err));
        });
    }
    useEffect(() => {
        getRank(playerOne.username, "one");
        getRank(playerTwo.username, "two");
    }, []);
  return (
    <div className="compare">
    <table className="table table-striped table-dark">
      <thead>
        <tr>
          <th colSpan="9"><h3 className="compare__vs">{playerOne.username} vs {playerTwo.username}</h3></th>
        </tr>
        <tr>
          <th scope="col">Lvl</th>
          <th scope="col">XP</th>
          <th scope="col">Rank</th>
          <th></th>
          <th scope="col">Skill</th>
          <th></th>
          <th scope="col">Rank</th>
          <th scope="col">XP</th>
          <th scope="col">Lvl</th>
        </tr>
      </thead>
      <tbody>
        {skills.map((skill, key) => (
          <tr key={key}>
            <td>{playerOne[skill.toLowerCase() + "_level"]}</td>
            <td><NumberFormat value={playerOne[skill.toLowerCase() + "_exp"]}displayType={'text'} thousandSeparator={true} /></td>
            <td className="compare__win">{!rankOne[key] ? <i className="fas fa-spinner fa-spin fa-1x"></i> : rankOne[key]}</td>
            <td>{playerOne[skill.toLowerCase() + "_exp"] > playerTwo[skill.toLowerCase() + "_exp"] ? <img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/up.png`}/> : playerOne[skill.toLowerCase() + "_exp"] < playerTwo[skill.toLowerCase() + "_exp"] ? <img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/down.png`}/> : <img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/equal.png`}/>}</td>
            <td><img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/skill_icon_${skill}.gif`} width={24} height={24}/></td>
            <td>{playerOne[skill.toLowerCase() + "_exp"] < playerTwo[skill.toLowerCase() + "_exp"] ? <img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/up.png`}/> : playerOne[skill.toLowerCase() + "_exp"] > playerTwo[skill.toLowerCase() + "_exp"] ? <img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/down.png`}/> : <img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/equal.png`}/>}</td>
            <td className="compare__lose">{!rankTwo[key] ? <i className="fas fa-spinner fa-spin fa-1x"></i> : rankTwo[key]}</td>
            <td><NumberFormat value={playerTwo[skill.toLowerCase() + "_exp"]}displayType={'text'} thousandSeparator={true} /></td>
            <td>{playerTwo[skill.toLowerCase() + "_level"]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default Compare