import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import '../css/HiscoresProfile.css';

function HiscoresProfile({player, skills}) {
  const ranks = [];
  const [rank, setRank] = useState([]);

 function getRank(username) {
  setRank([]);
  skills.map((skill) => {
      axios.get(`https://elysiumps.com/api/api.php?request=hiscores_rank&skill=${skill}&user=${username}`).then(response => {
        ranks.concat(response.data[0].rank);
        console.log(response.data[0].rank);
      }).catch(err => console.error(err));
    })
    setRank(ranks);
  }
  useEffect(() => {
    console.log(rank);
    getRank(player.username);
  }, []);
  return (
    <div className="profile">
      <h1><img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/skill_icon_overall.gif`} width={30} height={30} alt="" />{player.username} </h1>
      <table className="table table-striped table-dark table-sm">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col" width="30">Rank</th>
                <th scope="col" className="text-center">Lvl</th>
                <th scope="col" className="text-center">Experience</th>
            </tr>
        </thead>
        <tbody>
            {skills.map((skill, key) => (
                <tr>
                    <td><img src={`${process.env.PUBLIC_URL}/assets/Hiscores/img/skill_icon_${skill}.gif`} width={16} height={16}/>{skill}</td>
                    <td className="text-center">{!rank[key] ? <i className="fas fa-spinner fa-spin fa-1x"></i> : rank[key]}</td>
                    <td className="text-center">{player[skill.toLowerCase() + "_level"]}</td>
                    <td className="text-center"><NumberFormat value={player[skill.toLowerCase() + "_exp"]}displayType={'text'} thousandSeparator={true} /></td>
                </tr>
                
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default HiscoresProfile
