import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Hiscores from './components/pages/Hiscores';
import Vote from './components/pages/Vote';
import Shop from './components/pages/Shop';
import Context from './context/Context';

import {BrowserRouter as Router, Switch, Route, Navigate, useLocation, Routes} from "react-router-dom";

function App() {
  const location = useLocation();
  const home = location.pathname == "/" ? 'home' : location.pathname.replace('/', '');
  const className = home == "home" ? 'home' : 'container pages';
  const background = home == "home" ? 'no-bg' : 'bg';

  return (
    <div className={`App ${background}`}>
      <Header display={home} />
      <div className={className}>
        <Routes>
            <Route path="*" element={<Navigate to ="/" />}/>
            <Route path="/" element={<Home />} />
            <Route path="/vote" element={<Vote />} />
            <Route path="/hiscores" element={<Hiscores />} />
            <Route path="/shop" element={<Context><Shop /></Context>} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
